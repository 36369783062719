

#app {
  display: flex;       /* Use flex layout */
  flex-direction: column; /* Stack children vertically */
  height: 100%;          /* Occupy the full height of the viewport */
}

#app > .flex-grow-1 {
  flex-grow: 1;         /* Allow this div to expand and fill available space */
  /* Scrolling will be managed by the content within each route component */
}

div.hero img.app-logo {
  width: 60%;
  max-width: fit-content;
}

/* Styles for NavBar and Footer */
/* Ensure they are not taking more space than necessary and are fixed in size */
.navbar, .footer {
  flex-shrink: 0;       /* Prevent these components from shrinking */
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.errors { /* Assuming your Errors component has or can accept a class */
  margin-bottom: 20px; /* Adds some space between the errors and the spinner image */
  /* Add more styling here as needed */
}

.result-block-container .result-block {
  opacity: 1;
}

.nav-dropdown .dropdown-menu {
  right: 0;
  left: auto;
}

footer {
  min-height: 30px;
}

div.toast {
  max-width: 100%;
}

div.modal-header .btn-close:before {
  content: '✕'; /* You can use any character or Unicode symbol here */
  font-size: 1.1rem; /* Adjust the size as needed */
  color: #000; /* Adjust the color as needed */
}

div.modal-header .btn-close {
  background: none; /* Removes any background styles */
  border: none; /* Removes any border styles */
  opacity: 1; /* Make sure it's fully visible */
}